import * as React from 'react';

import {APIImage, Icon, SmallModal} from '../global';
import {Container, Row, Column} from '../layout';
import {
  H4,
  Text,
  Title1,
  UnorderedList,
  UnorderedListItem,
} from '../typography';
import styled from '@emotion/styled';

export const PlusModal: React.FC = () => {
  const items = React.useMemo(() => {
    return [
      '2,000+ Guided Meditations',
      'Daily Coaching',
      'Sleep Content',
      'Mindful Exercises',
      'Mindful Radio',
      '10+ Courses from world-class teachers',
    ];
  }, []);
  return (
    <SmallModal
      name="plus"
      Top={<APIImage url="/images/plus-modal-small.png" />}
      closeButton
      Bottom={() => (
        <Container maxWidth="xs">
          <Row>
            <Column>
              <Title as="p" weight="bold" spacing={5}>
                Mindfulness <Icon name="plus-gold" width={96} height={39} />
              </Title>
              <H4 weight="bold" spacing={10}>
                Bring balance into your everyday life.
              </H4>
              <Text spacing={16}>
                We believe in a world where everybody has access to the
                life-changing skills of mindfulness.
              </Text>
            </Column>
            <Column>
              <UnorderedList>
                {items.map((i) => (
                  <UnorderedListItem tick key={i}>
                    {i}
                  </UnorderedListItem>
                ))}
              </UnorderedList>
            </Column>
          </Row>
        </Container>
      )}
    />
  );
};

export const Title = styled(Title1)(
    () => `
  display: flex;
  align-items: flex-start;
`,
);
